
  import { defineComponent, ref, watch } from 'vue';
  import { Typography, Checkbox, Textarea, Button } from '@voximplant/spaceui';
  import { togglePopup } from '@/store/popup';
  import { closeDrawer } from '@/store/drawer';
  import { useI18n } from 'vue-i18n';

  interface Item {
    id: string;
    value: string;
    isChecked: boolean;
  }

  export default defineComponent({
    name: 'FeedbackTab',
    components: { Typography, Checkbox, Textarea, Button },
    props: {
      items: {
        type: Object,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const refItems = ref(props.items?.items);
      watch(props, (newProps) => {
        refItems.value = newProps.items?.items;
      });
      const disabled = ref(true);
      const checkedItems = new Map();
      const checked = (event: boolean, item: Item) => {
        item.isChecked = event;
        if (item.isChecked) {
          checkedItems.set(item.id, item.value);
        } else {
          checkedItems.delete(item.id);
        }
        disabled.value = !checkedItems.size;
      };
      const userComments = ref('');
      watch(userComments, (text) => {
        disabled.value = !text;
      });
      const submitFeedback = () => {
        closeDrawer();
        togglePopup(false);
        console.log(checkedItems);
        console.log(userComments.value);
      };

      return {
        t,
        refItems,
        disabled,
        checked,
        userComments,
        submitFeedback,
      };
    },
  });
