
  import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
  import { Button, Typography, Popup, Icon } from '@voximplant/spaceui';
  import { useRouter } from 'vue-router';
  import { useStore } from 'effector-vue/composition';
  import { meetingStore } from '@/store/meeting';
  import Assessment from '@/components/feedback/Assessment.vue';
  import Feedback from '@/components/feedback/Feedback.vue';
  import Drawer from '@/components/Drawer.vue';
  import { openDrawer, closeDrawer } from '@/store/drawer';
  import { isPopupOpened, togglePopup } from '@/store/popup';
  import { useI18n } from 'vue-i18n';
  import { useIsMobileScreen, useIsMobilePlatform } from '@/hooks/useIsMobile';

  export default defineComponent({
    name: 'LeftMeeting',
    components: { Drawer, Feedback, Button, Typography, Popup, Icon, Assessment },
    setup() {
      const { t } = useI18n();
      let windowWidth = ref(window.innerWidth);
      const onWidthChange = () => (windowWidth.value = window.innerWidth);
      onMounted(() => window.addEventListener('resize', onWidthChange));
      onUnmounted(() => window.removeEventListener('resize', onWidthChange));
      const { isMobileScreen } = useIsMobileScreen();
      const { isMobilePlatform } = useIsMobilePlatform();
      const popupOpened = useStore(isPopupOpened);
      const openPopup = () => {
        openDrawer();
        togglePopup(true);
      };
      const closePopup = () => {
        togglePopup(false);
        closeDrawer();
      };
      const meeting = useStore(meetingStore);
      const router = useRouter();
      const rejoin = () => {
        /* if (meeting.value.meetingId) {
          router.replace({
            name: 'VideoConference',
            params: {
              conference: meeting.value.meetingId,
            },
          });
        } */
        window.location.href = document.location.href + meeting.value.meetingId;
        /*router.replace({
          name: 'Join',
          params: {
            conference: meeting.value.meetingId || '',
          },
        });*/
      };
      const openLink = (link: string) => {
        window.open(link);
      };
      const goHome = () => {
        /* router.replace({
          name: 'Join',
          params: {
            conference: meeting.value.meetingId || '',
          },
        }); */
        router.replace({
          name: 'Signin',
        });
      };
      const assessed = ref(false);

      return {
        t,
        isMobileScreen,
        isMobilePlatform,
        popupOpened,
        openPopup,
        closePopup,
        goHome,
        rejoin,
        assessed,
        openLink,
      };
    },
  });
